var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "misc"
    }
  }, [_c('img', {
    staticClass: "misc-mask",
    attrs: {
      "height": "226",
      "src": require("@/static/images/misc/misc-mask-".concat(_vm.$vuetify.theme.dark ? 'dark' : 'light', ".png"))
    }
  }), _c('v-img', {
    staticClass: "misc-tree",
    attrs: {
      "src": require("@/static/images/misc/tree-2.png")
    }
  }), _c('div', {
    staticClass: "page-title text-center px-5"
  }, [_c('h2', {
    staticClass: "text-2xl font-weight-semibold text--primary"
  }, [_vm._v(" You are not authorized! 🔐 ")]), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v(" You don’t have permission to access this page. Go Home! ")]), _c('div', {
    staticClass: "misc-character d-flex justify-center"
  }, [_c('v-img', {
    attrs: {
      "max-width": "700",
      "src": require("@/static/images/3d-characters/not-authorized.png")
    }
  })], 1), _c('v-btn', {
    staticClass: "mb-4",
    attrs: {
      "color": "primary",
      "to": "/"
    }
  }, [_vm._v(" Back to home ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }